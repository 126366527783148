import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useSession } from 'src/sdk/session'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import Newsletter from 'src/components/sections/Newsletter'
import '../../styles/pages/preguntas-frequentes.scss'
import Accordion, { AccordionItem } from 'src/components/ui/Accordion'
import { useState } from 'react'
import { questions } from 'src/mocks/FAQ.json'

interface Props {
  data: TAllCmsInstitutional
  location: {
    pathname: string
  }
}
interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

const Page = ({ location, data: { site } }: Props) => {
  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([])
  )

  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      indicesExpanded.delete(index)
      setIndicesExpanded(new Set(indicesExpanded))
    } else {
      setIndicesExpanded(new Set(indicesExpanded.add(index)))
    }
  }

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <div style={{ paddingTop: '16px' }} />

      <section className="preguntas-container">
        <div className="title-container-preguntas">
          <h2>Preguntas Frecuentes</h2>
          <div className="black-rectangle" />
        </div>
        <Accordion expandedIndices={indicesExpanded} onChange={onChange}>
          {questions.map(({ question, answer }, index) => {
            return (
              <AccordionItem
                isExpanded={indicesExpanded.has(index)}
                buttonLabel={question}
                key={index}
              >
                <div dangerouslySetInnerHTML={{ __html: answer }} />
              </AccordionItem>
            )
          })}
        </Accordion>
      </section>

      <div className="newsletter-container-inst-page">
        <StockAvailable icon />
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />{' '}
      </div>
    </>
  )
}

export const query = graphql`
  query LandingPageFAQQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage(
      filter: {
        seo: {
          siteMetadataWithSlug: { slug: { eq: "/lp/preguntas-frecuentes" } }
        }
      }
    ) {
      edges {
        node {
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          name
          sections {
            data
          }
        }
      }
    }
  }
`

export default Page

export { Head } from 'src/components/common/Head'
